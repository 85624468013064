<template>
<!-- 企业文化 -->
  <div class="about">
    <div class="nuox-about-header">
      <img src="http://39.104.95.82:8087/img/header/1.jpg" >
    </div>
    <div class="module-content">
      <div class="nuox-breadcrumb">
        <span class="nuox-breadcrumb-item"><router-link tag="span" :to="$t('lang.headerMenus[0].pageSrc')">{{ $t("lang.headerMenus[0].name") }}</router-link></span>
        <span class="iconfont icon-dian"></span>
        <span class="nuox-breadcrumb-item">{{ $t("lang.headerMenus[1].name") }}</span>
        <span class="iconfont icon-dian"></span>
        <span class="nuox-breadcrumb-item">{{ $t("lang.headerMenus[1].childrens[1].name") }}</span>
      </div>
      <div class="module-line"></div>
        <div :class="$i18n.locale=='en'?'module-nav-en':'module-nav'">
          <ul class="menu-vertical-nav">
            <li v-for="(item,index) in menus" :key="index" @click="checknav(item)">
              <div :class="'title '+[item.id==checkId?'open':'']" ><span>{{item.name}}</span><span v-if="item.childrens && item.childrens.length>0"  :class="[item.id==checkId?'el-icon-arrow-down':'el-icon-arrow-right']+ ' icon-down'"></span></div>
              <dl v-if="item.childrens && item.id==checkId && item.childrens.length>0">
                <dd :class="[item2.id==checkChildrenId?'in':'']" v-for="(item2,index2) in item.childrens" :key="index2" @click="checknavchildren(item,item2)">{{item2.name}}</dd>
              </dl>
            </li>
          </ul>
        </div>
        <div class="nuox-module">
          <div class="nuox-content-title">
            <div class="title-name">
              {{ $t("lang.headerMenus[1].childrens[1].name") }}
              <div class="xian"></div>
            </div>
          </div>
          <div class="nuox-module-culture culturetest">
            <el-row>
              <el-col :span="item.span" v-for="(item,index) in pageList" :key="index">
                <div :class="'grid-content text-card '+ item.className" v-if="item.type==0">
                  <div class="text-title" v-if="$i18n.locale=='zh'"><div class="text-tt">{{item.nameZh}}<div class="text-line bg-white" v-if="item.className=='bg-blue'"></div><div class="text-line bg-blue" v-if="item.className=='text-blue'"></div></div></div>
                  <div class="text-title" v-if="$i18n.locale=='en'"><div class="text-tt">{{item.nameEn}}<div class="text-line bg-white" v-if="item.className=='bg-blue'"></div><div class="text-line bg-blue" v-if="item.className=='text-blue'"></div></div></div>
                  <div class="nuox-bottom" v-if="$i18n.locale=='zh'" v-html="item.contentZh"></div>
                  <div class="nuox-bottom" v-if="$i18n.locale=='en'" v-html="item.contentEn"></div>
                </div>
                <div class="grid-content" v-if="item.type==1&&item.images.length>0"><img :src="item.images[0]"></div>
              </el-col>
            </el-row>
          </div>
        </div>
      <div class="module-line"></div>
    </div>
  </div>
</template>
<script>
export default {
  computed:{
    menus:function(){
      return this.$t('lang.headerMenus[1].childrens');
    }
  },
  data:function() {
    return {
        checkId:9,
        checkChildrenId:0,
        pageNum:1,
        pageCount:30,
        pageList:[]
    };
  },
  mounted(){
    this.init();
  },
  methods:{
    async init(){
      var locale =  this.$i18n.locale;
      var baseUrl = this.$baseURL;
      var that = this;
       var pageNum = this.pageNum;
      var pageCount = this.pageCount;
      const { data: res } = await this.$http.get(
        "webSite/about_corporate_culture?pageNum="+pageNum+"&pageCount="+pageCount
      );
      if(res.code==0){
        var data = res.data.data;
        var pageList=[];
        for(var key in data){
          var item = {};
          var attList = data[key].attList;
          var images = [];
          for(var index in attList){
            images.push(baseUrl+attList[index].attPath+attList[index].attRealName);
          }
          item.images=images;
          item.type=data[key].cultureType;
          item.nameZh=data[key].cultureNameZh;
          item.nameEn=data[key].cultureNameEn;
          item.contentZh=data[key].cultureContentZh;
          item.contentEn=data[key].cultureContentEn;
          if(key==4){
            item.span=16
          }else{
             item.span=8
          }
          if(key==2||key==6){
            item.className = "text-blue";
          }else{
            item.className = "bg-blue";
          }
          pageList.push(item);
        }
        that.pageList = pageList;
      }
    },
    checknav(e){
      this.checkId = e.id;
      if(e.pageSrc){
          this.$router.push({ 
            path: e.pageSrc,
            query: {}
          })
      }
    },
    checknavchildren(item,item2){
      this.checkId=item.id;
      this.checkChildrenId=item2.id;
    }
  }
}
</script>
<style>
@import '../../assets/css/about.css';
.culturetest img{
  width: 100%;
  height: 100%;
}
.culturetest .grid-content{
  margin: 10px;
  border: 1px solid #EBEBEB;
  height: 250px;
  text-align: center;
  font-size: 13px;
}

.culturetest .text-title{
  height: 50%;
  position: relative;
}
.culturetest .text-tt{
  position: absolute;
  bottom: 0;
  width: 100%;
  padding-bottom: 15px;
  font-weight: bold;
  font-size: 14px;
}
.culturetest .text-line{
  margin: 0 auto;
  width: 30px;
  height: 2px;
  margin-top: 15px;
}
.culturetest span{
  margin: 5px;
}
</style>